import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Todo.module.css";
import "./index.css";
import { Hanko, register } from "@teamhanko/hanko-elements";
import { InviteCom } from "./GradientButton";
import { all } from "@teamhanko/hanko-elements/i18n/all";
import { zh } from '../src/i18n/zh'
import { CharmingAPIHost } from "./AppEnvironment";

const api = process.env.REACT_APP_HANKO_API!;
var inviteCode: string | any

function HankoAuth() {

  const navigate = useNavigate();
  const [error, setError] = useState<Error | null>(null);
  const [isobbk, setIsobbk] = useState<boolean>(false);
  const hankoAPI = useMemo(() => new Hanko(api), []);

  const redirectToTodos = useCallback(async () => {
    const user:any = await hankoAPI.user.getCurrent()

    const cookie = hankoAPI.token.client.cookie.getAuthCookie()
    console.log(`cookie:${JSON.stringify(cookie)}`);
    console.log(`user:${JSON.stringify(user)}`);

    let userString = JSON.stringify(user)
    const urlScheme = `oneshot://login?user=${userString}&token=${cookie}&inviter=${inviteCode}`;

    var now = new Date().valueOf();
    setTimeout(function () {
      if (new Date().valueOf() - now > 100) return;
      console.log("❌不可以打开App");
      // request a post API with fetch
      fetch(`https://${CharmingAPIHost}/createUser`, { //TODO: change to https://api.charming.chat    http://127.0.0.1:8787
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${cookie}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: user.email,
          invite_id: inviteCode
        })
      })
        .then(response => response.json())
        .then(data => {
          console.log(`我也不知道啊这是什么：${JSON.stringify(data)}}`)
          // data.code
          //TODO: window.location.href = "https://apps.apple.com/cn/app/charming-%E8%8E%AB%E6%96%AF/id6446066593";
        })
        .catch(error => console.error(error))

    }, 50);
    window.location.href = urlScheme;
    console.log(`✅ 准备打开App:${urlScheme}`);

    var pr = "/profile"
    if (inviteCode !== null && inviteCode !== "") {
      pr = "/profile?code=" + inviteCode
    }
    navigate(pr, { replace: true });


  }, [navigate]);

  // 进入页面必展示
  useEffect(() => {
    onlod()
  }, [])

  useEffect(() => {
    register(api, {shadow: true, translations: {...all, zh}}).catch(setError);;
  }, []);

  useEffect(
    () => hankoAPI.onAuthFlowCompleted(() => redirectToTodos()),
    [hankoAPI, redirectToTodos]
  );

  const onlod = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    console.log(`code: ${code}`);
    inviteCode = code

    const isobbk = urlParams.get('isobbk');
    console.log(`isobbk: ${isobbk}`);
    setIsobbk(isobbk == '1')
  }

  return (
    <div>
      <div className={styles.content}>
        <div className={styles.error}>{error?.message}</div>
        {/* <hanko-auth lang='zh'/>  */}
        <hanko-auth lang='en'/>
      </div>
      {/* {!isobbk && <InviteCom />} */}
    </div>
  );
}

export default HankoAuth;
