import { Translation } from "@teamhanko/hanko-elements"; 

export const zh: Translation = {
  headlines: {
    error: "出现错误",
    loginEmail: "登录或注册",
    loginFinished: "登录成功",
    loginPasscode: "输入访问码",
    loginPassword: "输入密码",
    registerAuthenticator: "注册 PassKey",
    registerConfirm: "创建帐号？",
    registerPassword: "设定新密码",
    profileEmails: "电子邮件地址",
    profilePassword: "密码",
    profilePasskeys: "PassKey",
    isPrimaryEmail: "主要电子邮件地址",
    setPrimaryEmail: "设定为主要电子邮件地址",
    emailVerified: "已验证",
    emailUnverified: "未验证",
    emailDelete: "删除",
    renamePasskey: "重命名 PassKey",
    deletePasskey: "删除 PassKey",
    lastUsedAt: "上次使用于",
    createdAt: "创建于",
    connectedAccounts: "关联帐号",
    deleteAccount: "删除帐号"
  },
  texts: {
    enterPasscode: '请输入发送到 "{emailAddress}" 的访问码。',
    setupPasskey: "通过 PassKey 方便且安全地登录您的帐号。注意：您的生物特征数据仅存储在您的设备上，不会与任何人分享。",
    createAccount: '"{emailAddress}" 不存在帐号。是否创建一个新帐号？',
    passwordFormatHint: "密码长度应在 {minLength} 到 {maxLength} 个字符之间。",
    manageEmails: "您的电子邮件地址用于通信和身份验证。",
    changePassword: "设定新密码。",
    managePasskeys: "PassKey 用于登录此帐号。",
    isPrimaryEmail: "用于通信、访问码和 PassKey 的用户名。若要更改主要电子邮件地址，请先添加其他电子邮件地址并将其设定为主要地址。",
    setPrimaryEmail: "将此电子邮件地址设定为主要地址，以用于通信、访问码和 PassKey 的用户名。",
    emailVerified: "此电子邮件地址已验证。",
    emailUnverified: "此电子邮件地址未验证。",
    emailDelete: "如果删除此电子邮件地址，将不能再使用它登录您的帐号。创建时使用此电子邮件地址创建的 PassKey 将不会受到影响。",
    emailDeleteThirdPartyConnection: "如果删除此电子邮件地址，将无法使用它登录或重新连接您的 {provider} 帐号。创建时使用此电子邮件地址创建的 PassKey 将不会受到影响。",
    emailDeletePrimary: "无法删除主要电子邮件地址。请先添加其他电子邮件地址并将其设定为主要地址。",
    renamePasskey: "为 PassKey 设置名称，以便您知道其存储位置。",
    deletePasskey: "从您的帐号中删除此 PassKey。请注意，PassKey 将继续存在于您的设备上，同时需要在设备上删除它。",
    deleteAccount: "确认删除此帐号？所有数据将立即被删除且无法恢复。"
  },
  labels: {
    or: "或",
    no: "否",
    yes: "是",
    email: "电子邮件",
    continue: "继续",
    skip: "跳过",
    save: "保存",
    password: "密码",
    signInPassword: "通过密码登录",
    signInPasscode: "通过访问码登录",
    forgotYourPassword: "忘记密码？",
    back: "返回",
    signInPasskey: "通过 PassKey 登录",
    registerAuthenticator: "注册 PassKey",
    signIn: "登录",
    signUp: "注册",
    sendNewPasscode: "发送新的访问码",
    passwordRetryAfter: "{passwordRetryAfter} 后重试",
    passcodeResendAfter: "{passcodeResendAfter} 后重新发送访问码",
    unverifiedEmail: "未验证",
    primaryEmail: "主要",
    setAsPrimaryEmail: "设定为主要",
    verify: "验证",
    delete: "删除",
    newEmailAddress: "新电子邮件地址",
    newPassword: "新密码",
    rename: "重命名",
    newPasskeyName: "新 PassKey 名称",
    addEmail: "添加电子邮件地址",
    changePassword: "更改密码",
    addPasskey: "添加 PassKey",
    webauthnUnsupported: "您的浏览器不支持 PassKey",
    signInWith: "通过 {provider} 登录",
    deleteAccount: "是的，删除此帐号。"
  },
  errors: {
    somethingWentWrong: "发生技术错误，请稍后再试。",
    requestTimeout: "请求超时。",
    invalidPassword: "错误的电子邮件或密码。",
    invalidPasscode: "提供的访问码不正确。",
    passcodeAttemptsReached: "访问码已错误输入太多次。请请求一个新的访问码。",
    tooManyRequests: "请求过多。请等待后重试所需操作。",
    unauthorized: "您的会话已过期。请重新登录。",
    invalidWebauthnCredential: "无法再使用此 PassKey。",
    passcodeExpired: "访问码已过期。请请求一个新的访问码。",
    userVerification: "需要用户验证。请确保您的 PassKey 受到 PIN 码或生物特征保护。",
    emailAddressAlreadyExistsError: "电子邮件地址已存在。",
    maxNumOfEmailAddressesReached: "无法添加更多的电子邮件地址。",
    thirdPartyAccessDenied: "访问被拒绝。请求已被用户取消或供应商因其他原因拒绝访问。",
    thirdPartyMultipleAccounts: "无法识别帐号。电子邮件地址已被多个帐号使用。",
    thirdPartyUnverifiedEmail: "需要验证电子邮件地址。请与您的供应商验证使用的电子邮件地址。"
  }
};